import React from "react";
import { motion } from "framer-motion";
import usePortal from "react-cool-portal";
import styled from "styled-components";

import { FlexDiv } from "@/components/Atoms/Box";
import SVGSprite from "@/components/Atoms/SVGSprite";

import { useLockBodyScroll } from "@/hooks";

import theme from "@/foundation/Theme";
import { Typography } from "@/foundation/Typography/V2";
import TncStrip from "@/containers/BootCampPages/Components/TermsAndConditionStrip";

export const AnimateDirection = {
  RIGHT: "right",
  BOTTOM: "bottom",
  CENTER: "center",
  LEFT: "left",
  RIGHT_TOP: "rightTop",
};

const getAnimateValue = (animateDirection) => {
  const { RIGHT, BOTTOM, CENTER, LEFT, RIGHT_TOP } = AnimateDirection;
  switch (animateDirection) {
    case RIGHT:
      return {
        initial: { opacity: 0, x: "50%" },
        animate: { opacity: 1, x: "0" },
        exit: { opacity: 0, x: "50%" },
      };
    case LEFT:
      return {
        initial: { opacity: 0, x: "-50%" },
        animate: { opacity: 1, x: "0" },
        exit: { opacity: 0, x: "-50%" },
      };

    case BOTTOM:
      return {
        initial: { opacity: 0, y: "50%", x: "-50%" },
        animate: { opacity: 1, y: "-78px", x: "-50%" },
        exit: { opacity: 0, y: "50%", x: "-50%" },
      };
    case CENTER:
      return {
        initial: { opacity: 0, y: "-50%", x: "50%" },
        animate: { opacity: 1, y: "-50%", x: "50%" },
        exit: { opacity: 0, y: "-50%", x: "50%" },
      };
    case RIGHT_TOP: {
      return {
        initial: { opacity: 0, y: "0%", x: "0px" },
        animate: { opacity: 1, y: "0%", x: "0px" },
        exit: { opacity: 0, y: "0%", x: "0px" },
      };
    }
    default:
      return {
        initial: { opacity: 0, x: "50%" },
        animate: { opacity: 1, x: "0" },
        exit: { opacity: 0, x: "50%" },
      };
  }
};

function ModalMotion({
  onClose,
  showClose = false,
  closeIcon = "icon-close",
  iconDimension = "13px",
  iconFill = theme.colors.gray_100,
  iconPadding = "0px",
  iconTopPosition = "54px",
  iconRightPosition = "54px",
  bodyPadding = "35px",
  bodyDirection = "column",
  closeBtnPadding = "5px",
  children,
  borderRadius = "0px",
  overlayClosable = false,
  width = "383px",
  zIndex = 1050,
  headerStyle = {},
  bodyStyle = {},
  onEditClick,
  onDeleteClick,
  headerActions = null,
  showHeader = true,
  headerVariant = "h1",
  headerText = "",
  headerPadding = "65px 0 0 0",
  headerMaxWidth = "100%",
  headerMargin = "0 auto",
  portalContainerId = undefined,
  portal = true,
  closeButtonRound = false,
  verticalWidth = "12px",
  animateDirection = AnimateDirection.LEFT,
  height = "auto",
  overlayBgColor = theme.colors.layOvers.black,
  overlayOpacity = "0.3",
  showOverlay = true,
  showShadow = false,
  boxShadow = `0 0 8px rgba(${theme.colors.layOvers.black},0.2)`,
  mobilewidth = "100%",
  btnPos = {},
  hasTncModel=false,
  bodyBorderRadius="24px",
  iconBorderRadius="0px",
  ...rest
}) {
  const { initial, animate, exit } = getAnimateValue(animateDirection);
  useLockBodyScroll();
  const { Portal } = usePortal({
    clickOutsideToHide: false,
    escToHide: false,
    containerId: portalContainerId,
  });

  const content = (
    <>
      {showOverlay && (
        <ModalOverlay
          onClick={
            overlayClosable && onClose
              ? () => {
                  onClose();
                }
              : undefined
          }
          zindex={zIndex}
          overlayBgColor={overlayBgColor}
          overlayOpacity={overlayOpacity}
        ></ModalOverlay>
      )}
      <ModalContainer
        animatedirection={animateDirection}
        key="modal"
        initial={initial}
        animate={animate}
        exit={exit}
        transition={{
          ease: "easeInOut",
          duration: 0.2,
        }}
        width={width}
        height={height}
        zindex={zIndex}
        borderradius={borderRadius}
        showshadow={showShadow ? 1 : undefined}
        shadow={boxShadow}
        mobilewidth={mobilewidth}
        btnpos={btnPos}
        {...rest}
      >
        {showClose && (
          <CloseIcon
            icon={closeIcon}
            height={iconDimension}
            width={iconDimension}
            onClick={onClose}
            fill={iconFill}
            iconpadding={iconPadding}
            icontopposition={iconTopPosition}
            iconrightposition={iconRightPosition}
            borderRadius={iconBorderRadius}
          />
        )}
        {showHeader && headerText && (
          <FlexDiv
            maxWidth={headerMaxWidth}
            m={headerMargin}
            width={"100%"}
            flexDirection={"column"}
            p={headerPadding}
            position="relative"
          >
            <Typography variant={headerVariant}>{headerText}</Typography>
          </FlexDiv>
        )}
        <Body
          bodyBorderRadius={bodyBorderRadius}
          bodyPadding={bodyPadding}
          style={bodyStyle || {}}
          height={height}
          flexDirection={bodyDirection}
          animatedirection={animateDirection}
        >
          {children}
        </Body>
        {hasTncModel && <TncStrip/>}
      </ModalContainer>
    </>
  );

  return portal ? <Portal>{content}</Portal> : content;
}

export default ModalMotion;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ zindex }) => zindex};
  background-color: ${({ overlayBgColor, overlayOpacity }) =>
    `rgba(${overlayBgColor},${overlayOpacity});`};
`;

const Body = styled(FlexDiv)`
  height: ${({ height }) => height};
  overflow: hidden auto;
  padding: ${({ bodyPadding }) => bodyPadding};
  flex-grow: 1;
  max-height: ${({ animatedirection }) =>
    animatedirection === AnimateDirection.CENTER
      ? "calc(100vh - 90px)"
      : "100%"};
  background-color: #fff;    
  border-radius: ${({ bodyBorderRadius }) => bodyBorderRadius};
`;
const ModalContainer = styled(motion.div)`
  max-width: ${({ width }) => width};
  width: 100%;
  position: fixed;
  border-radius: ${({ borderradius }) => borderradius};
  height: ${({ height }) => height};
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  text-align: left;
  z-index: ${({ zindex }) => zindex};
  background-color: #C7D7FE;
  border-radius: 24px;
  @media only screen and (max-width: 449px) {
    max-width: ${({ mobilewidth }) => mobilewidth};
  }
  ${({ showshadow, shadow }) =>
    showshadow &&
    `
    box-shadow: ${shadow};
  `};
  ${({ animatedirection, btnpos }) =>
    animatedirection === AnimateDirection.BOTTOM
      ? `
            bottom:0;
            left: 50%;
        `
      : animatedirection === AnimateDirection.CENTER
      ? `
            right: 50%;
            top: 50%;
            @media only screen and (max-width: 439px){
              max-width: 95%;
            }
        `
      : animatedirection === AnimateDirection.RIGHT_TOP
      ? `
              right: ${btnpos?.right};
              top: ${btnpos?.top};
              @media only screen and (max-width: 439px){
                max-width: 95%;
              }
          `
      : animatedirection === AnimateDirection.LEFT
      ? `
            left: 0;
            top: 0;
      `
      : `
        right: 0;
        top: 0;
    `};
`;
const CloseIcon = styled(SVGSprite)`
  position: absolute;
  top: ${({ icontopposition }) => icontopposition};
  padding: ${({ iconpadding }) => iconpadding};
  right: ${({ iconrightposition }) => iconrightposition};
  border-radius: ${({ borderRadius }) => borderRadius}
  cursor: pointer;
  background-color: #fff;    
  z-index: 10;
`;
