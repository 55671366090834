import localFont from "@next/font/local";
import { DM_Sans, Poppins } from "@next/font/google";

export const ralewayRegular = localFont({
  src: "../../public/static/fonts/raleway-regular.ttf",
  variable: "--font-400",
  display: "swap",
  weight: "400",
});

export const ralewayMedium = localFont({
  src: "../../public/static/fonts/raleway-medium.ttf",
  variable: "--font-500",
  display: "swap",
  weight: "500",
});

export const ralewaySemiBold = localFont({
  src: "../../public/static/fonts/Raleway-SemiBold.ttf",
  variable: "--font-600",
  display: "swap",
  weight: "600",
});

export const ralewayBold = localFont({
  src: "../../public/static/fonts/Raleway-Bold.ttf",
  variable: "--font-700",
  display: "swap",
  weight: "700",
});

export const ralewayExtrabold = localFont({
  src: "../../public/static/fonts/raleway-extrabold.ttf",
  variable: "--font-800",
  display: "swap",
  weight: "800",
});

export const raleway = localFont({
  src: [
    {
      path: "../../public/static/fonts/raleway-regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/raleway-medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Raleway-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Raleway-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/raleway-extrabold.ttf",
      weight: "800",
      style: "normal",
    },
  ],
  variable: "--raleway",
});

export const manropeExtraLight = localFont({
  src: "../../public/static/fonts/Manrope-ExtraLight.ttf",
  variable: "--man-200",
  display: "swap",
  weight: "200",
});

export const manropeLight = localFont({
  src: "../../public/static/fonts/Manrope-Light.ttf",
  variable: "--man-300",
  display: "swap",
  weight: "300",
});

export const manropeRegular = localFont({
  src: "../../public/static/fonts/Manrope-Regular.ttf",
  variable: "--man-400",
  display: "swap",
  weight: "400",
});

export const manropeMedium = localFont({
  src: "../../public/static/fonts/Manrope-Medium.ttf",
  variable: "--man-500",
  display: "swap",
  weight: "500",
});

export const manropeSemiBold = localFont({
  src: "../../public/static/fonts/Manrope-SemiBold.ttf",
  variable: "--man-600",
  display: "swap",
  weight: "600",
});

export const manropeBold = localFont({
  src: "../../public/static/fonts/Manrope-Bold.ttf",
  variable: "--man-700",
  display: "swap",
  weight: "700",
});

export const manropeExtraBold = localFont({
  src: "../../public/static/fonts/Manrope-ExtraBold.ttf",
  variable: "--man-800",
  display: "swap",
  weight: "800",
});

export const manrope = localFont({
  src: [
    {
      path: "../../public/static/fonts/Manrope-ExtraLight.ttf",
      weight: "200",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Manrope-Light.ttf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Manrope-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Manrope-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Manrope-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Manrope-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/Manrope-ExtraBold.ttf",
      weight: "800",
      style: "normal",
    },
  ],
  variable: "--man",
});

export const soraSemiBold = localFont({
  src: "../../public/static/fonts/Sora-SemiBold.ttf",
  variable: "--sora-600",
  display: "swap",
  weight: "600",
});

export const soraBold = localFont({
  src: "../../public/static/fonts/Sora-Bold.ttf",
  variable: "--sora-700",
  display: "swap",
  weight: "700",
});

export const soraExtraBold = localFont({
  src: "../../public/static/fonts/Sora-ExtraBold.ttf",
  variable: "--sora-800",
  display: "swap",
  weight: "800",
});

export const dmSans = DM_Sans({
  style: ["normal", "italic"],
  weight: ["400", "500", "700"],
  variable: "--dm-sans",
  preload: false,
});

export const poppins = Poppins({
  style: ["normal", "italic"],
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  variable: "--poppins",
  preload: false,
});

export const satoshiBold = localFont({
  src: "../../public/static/fonts/Satoshi-Bold.ttf",
  variable: "--satoshi",
  display: "swap",
  weight: "700",
});

export const forum = localFont({
  src: "../../public/static/fonts/Forum-Regular.ttf",
  variable: "--forum",
  weight: "400",
});

export const mori = localFont({
  src: "../../public/static/fonts/MoriGothic-Regular.ttf",
  variable: "--mori",
  weight: "400",
});

export const fontsClassNameList = [
  ralewayRegular.variable,
  ralewayMedium.variable,
  ralewaySemiBold.variable,
  ralewayBold.variable,
  ralewayExtrabold.variable,
  raleway.variable,
  manropeExtraLight.variable,
  manropeLight.variable,
  manropeRegular.variable,
  manropeMedium.variable,
  manropeSemiBold.variable,
  manropeBold.variable,
  manropeExtraBold.variable,
  manrope.variable,
  soraSemiBold.variable,
  soraBold.variable,
  soraExtraBold.variable,
  dmSans.variable,
  poppins.variable,
  satoshiBold.variable,
  forum.variable,
  mori.variable,
];

const fontsClassName = fontsClassNameList.join(" ");

export default fontsClassName;
